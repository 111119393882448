<template>
  <div>
    <el-row>
      <el-button
        style="float: left; margin: 7px"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd"
      >
        {{ $t('CreateRole') }}
      </el-button>
    </el-row>
    <!-- 角色列表 -->
    <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="17" style="margin-bottom: 10px; padding: 7px;">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="role-span">{{ $t('RoleList') }}</span>
        </div>
        <el-table
          highlight-current-row
          v-loading="listLoading"
          :data="list" style="width: 100%;"
          :cell-style="{padding: '0', height: '40px'}"
          @current-change="handleCurrentChange"
        >
          <el-table-column prop="enabled" :label="$t('WhetherToEnable')" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.enabled" type="success">{{ $t('Enable') }}</el-tag>
              <el-tag v-else type="danger">{{ $t('Disable') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="name" :label="$t('Name')" />
          <el-table-column align="center" prop="updatedOn" :label="$t('UpdateDate')"  :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              {{ parseDateTime(scope.row.updatedOn) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdOn" :label="$t('CreateDate')"  :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              {{ parseDateTime(scope.row.createdOn) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('Edit')"
            width="130px"
          >
            <template slot-scope="scope">
              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleUpdate(scope.row)" />
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>

    <!-- 目錄權限設定 -->
    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="7" style="padding: 7px;">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <el-tooltip class="item" effect="dark" :content="$t('DirectorySettingToolTip')" placement="top">
            <span class="role-span">{{ $t('DirectoryPermissionSetting') }}</span>
          </el-tooltip>
          <el-button
            :disabled="!showButton"
            :loading="menuLoading"
            icon="el-icon-check"
            size="mini"
            style="float: right; padding: 6px 9px"
            type="primary"
            @click="saveMenu"
          >{{ $t('Save') }}</el-button>
        </div>
        <el-tree
          ref="menu"
          :data="$store.state.allMenu"
          :default-checked-keys="menuIds"
          show-checkbox
          node-key="id"
          :props="customProps"
          @check="menuChange"
          @check-change="menuCheckChange"
          @node-click="nodeClick"
          :render-content="renderContent"
          :check-strictly = true
        />
      </el-card>
    </el-col>

    <!-- 編輯 -->
    <el-dialog width="500px" :title="renderTitle(dialogStatus)" :visible.sync="dialogFormVisible" >
      <el-form ref="dataForm" :rules="rules" :model="temp" size="small" :inline="true" label-width="100px" >
        <el-form-item :label="$t('Status')" prop="enabled">
          <el-select v-model="temp.enabled" placeholder="是否啟用" style="width: 140px">
            <el-option :label="$t('Enable')" :value="true" />
            <el-option :label="$t('Disable')" :value="false" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('RoleName')" prop="name" >
          <el-input v-model="temp.name" />
        </el-form-item>
        <el-form-item :label="$t('CreateDate')" prop="createdOn" v-if="dialogStatus=='update'">
          {{ parseDateTime(temp.createdOn) }}
        </el-form-item>
        <el-form-item :label="$t('UpdateDate')" prop="updatedOn" v-if="dialogStatus=='update'">
          {{ parseDateTime(temp.updatedOn) }}
        </el-form-item>
      </el-form>

       <div style="text-align: center">
        <el-button size="small" @click="dialogFormVisible = false">
          {{ $t('Cancel') }}
        </el-button>
        <el-button size="small" type="primary" @click="updateData()">
          {{ $t('Confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
  name: 'Role',
  components: {
  },
  mixins: [mixins],
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      treeData: [],
      list: [],
      listLoading: false,
      temp: {
        id: undefined,
        enabled: true,
        name: undefined,
        menuIds: [],
        updatedOn: undefined,
        createdOn: undefined
      },
      dialogStatus: '',
      textMap: {
        update: this.$t('EditRole'),
        create: this.$t('AddRole')
      },
      dialogFormVisible: false,
      customProps: { // 自定義 el-tree props 屬性
        label: 'title'
      },
      currentRoleData: {}, // 當前角色資料
      menuLoading: false, showButton: false,
      menuIds: [], // 選中之menu
      rules: {
        enabled: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
        name: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
      }
    };
  },
  created() {
    this.getList();
    //refreshToken();
  },
  beforeDestroy(){
    console.log("call beforeDestroy");
    //stopRefreshToken();
  },
  watch: {
    /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
    '$i18n.locale': function(val) {
      console.log('Role組件重載前')
      this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
      console.log('Role組件重載完成')
      },
    /* ----------------------------------------------------------------------------- */
  },
  methods: {
    //產生dialog時套用i18N,更新title
    renderTitle(value){
      console.log('value=',value);
      if(value == 'update')
        return this.$t('EditRole')
      else
        return this.$t('AddRole')
    },
    //產生Node時套用i18N
    renderContent(h, { node, data, store }) {
      return (
          <span class="custom-tree-node">
            <span>{this.$t(node.label)}</span>
          </span>);
    },
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/role').then(res=> {
        this.list = res.data
        console.log("find roles",this.list)
        this.listLoading = false
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        enabled: true,
        name: undefined,
        menuIds: [],
        updatedOn: undefined,
        createdOn: undefined
      }
    },
    handleAdd: function() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      // console.log(this.temp)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData: function() {
      var showMessage = this.$t('Add');
      if(this.dialogStatus == 'update')
        showMessage = this.$t('Edit')

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.axios.put('/api/v1/role', this.temp).then(()=> {
            this.$message({
              showClose: true,
              message: showMessage+this.$t('Success'),
              type: 'success'
            })
            this.refreshChange()
            this.dialogFormVisible = false
          }).catch(err => {
            console.log('err:' + err)
            this.$message({
              message: showMessage+this.$t('Fail'),
              type: 'error'
            })
          })
        }
      })
    },
    // 角色觸發
    handleCurrentChange(val) {
      //console.log('handleCurrentChange')
      if (val) {
        console.log(val);
        const _this = this
        // 清空菜單的選中
        this.$refs.menu.setCheckedKeys([])
        // 保存當前的角色
        this.currentRoleData = val
        // 初始化默認選中的menu
        _this.menuIds = []
        val.menuIds.forEach(function(data) { // 角色原已選中的menu
          //console.log("menuid="+data);
          _this.menuIds.push(data)
        })
        this.showButton = true
      }
    },
    // checkedKeys change
    menuChange(checkedNodes, checkedKeys) {
      this.menuIds = checkedKeys.checkedKeys
      //console.log(this.menuIds)
    },
    // checkChange event
    menuCheckChange(data,checked,intermediate){
      let _this = this;
      //console.log('selected-data-id',data.id)

      //若為取消勾選
      if(checked === false)
      {
        //當前發生變化的節點
        let checkdNode = _this.$refs.menu.getNode(data);
        if(data.children){
          //console.log(data.children)
          //所有子節點皆取消勾選
          data.children.map(item =>{
            this.$refs.menu.setChecked(item.id,false,true)
          })
        }
        //檢查當前父節點的所有子節點,若無子節點勾選,則取消勾選父節點
        if(checkdNode.parent && checkdNode.parent.childNodes.every(ele => !ele.checked)){
          checkdNode.parent.checked = false;
        }
      }
      //若為選取
      else {
        //當前發生變化的節點
        let checkdNode = _this.$refs.menu.getNode(data);
        //若有父節點
        if(checkdNode.parent)
        {
          //indeterminate=true為半勾選
          //checkdNode.parent.indeterminate = true;
          //勾選父節解
          checkdNode.parent.checked = true;
        }

      }



    },
    nodeClick(item,e){

    if(e.parent !== undefined)
    {
      console.log('e.parent',e.parent)
    }


    },
    // 保存菜單
    saveMenu() {
      this.menuLoading = true
      // 得到已選中的 key 值
      const _this = this
      this.currentRoleData.menuIds = [] // 清空原menu
      this.menuIds.forEach(function(id) { // 存入目前勾選menu
        _this.currentRoleData.menuIds.push(id)
      })
      console.log('saveMenu',this.currentRoleData);
      this.axios.put('/api/v1/role', this.currentRoleData).then(()=> {
            this.axios.put('/api/v1/resource', this.currentRoleData).then(()=> {
              this.$message({
                showClose: true,
                message: this.$t('Save')+this.$t('Success'),
                type: 'success'
              })
            }).catch(err => {
              console.log('err:' + err)
              this.$message({
                message: this.$t('Save')+this.$t('Fail'),
                type: 'error'
              })
            })

        this.menuLoading = false
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Save')+this.$t('Fail'),
          type: 'error'
        })
      })
    },
    refreshChange() {
      this.getList()
    },
  }
}
</script>
<style lang="scss" scoped>
  .role-span {
    font-weight: bold;color: #303133;
    font-size: 15px;
  }
</style>
